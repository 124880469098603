import React from 'react'
import NavBar from '../nav-bar'

import SearchLink from '../search-link'
import { Container, SubTitle, Wrapper } from './styled'

export default function Header() {
  return (
    <Container>
      <NavBar />
      <Wrapper>
        <SubTitle>
          <text>Como podemos <b>ajudar</b> ?</text>
        </SubTitle>
        <SearchLink />
      </Wrapper>
    </Container>
  )
}
