import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import { Hits } from 'react-instantsearch-dom';
import { Container, StyledImg, Wrapper } from './styled';
import { useDispatch } from 'react-redux';
import { currentProduct as creators } from '../../state/app'
export default function Products() {
  const dispatch = useDispatch();
  const { atendimentoImage, agendaImage } = useStaticQuery(
    graphql`
      query{
        atendimentoImage: file(relativePath:{eq:"atendimento.png"}){
          childImageSharp{
            fixed( width: 413, height: 209){
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        },
        agendaImage: file(relativePath:{eq:"agenda.png"}){
          childImageSharp{
            fixed( width: 413, height: 209){
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Container>
      <h2>Selecione o <b>produto</b></h2>
      <div>
        <Wrapper to="/faqs/" onClick={() => dispatch(creators("V4H agenda"))} >
          <StyledImg fixed={agendaImage.childImageSharp.fixed} />
        </Wrapper>
        <Wrapper to="/faqs/" onClick={() => dispatch(creators("V4H Atendimento"))}>
          <StyledImg fixed={atendimentoImage.childImageSharp.fixed} />
        </Wrapper>
      </div>
    </Container>
  )
}
