import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { colorPalette } from '../../styles/colorSchema';
export const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  h2{
    color: ${colorPalette.black};
    font-weight:${({ bold }) => bold ? bold : "normal"};
    margin:10px;
  }
  div{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  }
`;

export const Wrapper = styled(Link)`

  margin:10px;

  :hover{
    opacity:0.3;
  }
`;

export const StyledImg = styled(Img)`
  border-radius:5px;
`;