import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Container = styled.header`
  display:flex;
  height: 220px;
  flex-direction:column;
  flex:1;
  background-color:${colorPalette.primary};
`;

export const Wrapper = styled.div`
  padding-top:30px;
`

export const SubTitle = styled.text`
  display:flex;
  color:${colorPalette.white};
  justify-content:center;
  font-weight:${({ bold }) => bold ? bold : 'normal'};
  font-size:18px;
  @media (min-width:468px){
    font-size:36px;
  }
`;
