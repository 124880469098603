import React from "react"
import Header from "../components/header"

import Products from "../components/products"

import SEO from "../components/seo"
import Global from "../styles/global"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Global />
    <Header />
    <Products />
  </>
)

export default IndexPage
